html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font: 300 11px/1.4 'Helvetica Neue', sans-serif;
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #232323;
  overflow: hidden;
  display: block;
}
.places-container {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  width: 300px;
}

.map-container {
  width: 100%;
  height: 100vh;
}

.combobox-input {
  width: 100%;
  padding: 0.5rem;
}


.dashboard {
  margin-top: 200px;
  margin-left: 200px;
}

@media screen and (max-width: 1200px) {
  body {
    overflow: visible;
  }
}